import React from 'react';

import { Splash } from './Components/Screens/Splash';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from './Components/Screens/About';
import Congrats from './Components/Screens/Congrats';
import NotFound from './Components/Screens/NotFound';
import GameView from './Components/Screens/GameView';
import './App.css';

export default function App() {

	return (
		<div>
			<Router>
				<Routes>
					<Route path="/">
						<Route index element={<Splash />} />
						<Route path="splash" element={<Splash />} />
						<Route path="gameview" element={<GameView />} />
						<Route path="about" element={<About />} />
						<Route path="congrats" element={<Congrats />} />
						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</Router>
		</div>
	);
}
