import React, { Component } from 'react'
import { NavBar } from '../Navigation/NavBar'
import { BottomBar } from '../Styles/BottomBar'
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackward, faUserGroup, faRef, faBackwardresh } from '@fortawesome/free-solid-svg-icons'

export default () => {
  let navigate = useNavigate();

  return (
    <>
      <NavBar
        leftComponent={
          <>
            <button onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faBackward} />
            </button>
          </>
        }
      />
      <div style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <div>
          <div className='frame'>
          <h1>Congratulations!</h1>
          <p><img src="https://www.interactivejigsaw.com/finished.png" className="finalimage" style={{width: '1280px'}} /></p>
          </div>
        </div>
        <BottomBar />
      </div>
    </>
  )

}

